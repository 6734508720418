<cg-drawer
  class="[&>div>div>cg-icon>mat-icon>svg>path:nth-child(1)]:fill-cg-yellow [&>div>div>cg-icon>mat-icon>svg>path:nth-child(2)]:stroke-cg-black [&>div>div>cg-icon>mat-icon>svg>path:nth-child(3)]:stroke-cg-black [&>div>div>cg-icon>mat-icon>svg>path:nth-child(4)]:fill-cg-black [&>div>div>cg-icon>mat-icon>svg>path:nth-child(4)]:stroke-cg-yellow [&>div>div>cg-icon>mat-icon>svg>path:nth-child(5)]:stroke-cg-yellow [&>div>div]:p-0 [&>div]:p-6 t:[&>div]:m-0 t:[&>div]:max-w-xs d:[&>div]:max-w-[400px] d:[&>div]:p-10"
  [icon]="calendarCancelIcon"
  [content]="cancelAppointmentContent"
>
</cg-drawer>

<ng-template #cancelAppointmentContent>
  <div class="mt-4">
    <cg-olb-headline
      class="text-center part-[headline]:!mt-0"
      type="h1"
      [title]="'myCarglass.detail.overlays.cancelAppointment.title' | transloco"
    ></cg-olb-headline>

    <div class="mt-2 flex flex-col gap-4">
      @if (descriptionText) {
        <cg-paragraph
          class="text-center [&>p]:text-[0.8125rem] [&>p]:leading-5 d:[&>p]:text-[1rem] d:[&>p]:leading-6"
          [content]="descriptionText"
        >
        </cg-paragraph>
      }
      @if (hintText) {
        <cg-toast-message
          class="[&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(1)]:fill-black [&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(2)]:stroke-white [&>div>div>div>cg-icon>mat-icon>svg>path:nth-of-type(3)]:stroke-white [&>div>div>div>cg-icon>mat-icon>svg>path]:fill-black [&>div>div>div>cg-icon>mat-icon>svg>path]:stroke-transparent [&>div>div>div>cg-icon]:h-5 [&>div>div>div>cg-icon]:w-5 [&>div>div>div>div>div>cg-paragraph>p]:text-[0.8125rem] [&>div>div>div>div>div>cg-paragraph>p]:leading-5 [&>div>div>div>div]:!ml-2 [&>div>div>div]:!items-start [&>div>div]:!p-2"
          [content]="hintText"
        ></cg-toast-message>
      }
    </div>

    <div class="mt-6 flex flex-col gap-2">
      <button
        cg-base-button
        class="flex-grow [&>div]:px-4"
        [btnText]="'myCarglass.detail.overlays.cancelAppointment.buttonChangeAppointment' | transloco"
        (click)="changeAppointment()"
      ></button>
      <button
        cg-base-button
        class="flex-grow text-cg-anthracite [&>div]:px-4"
        [btnText]="'myCarglass.detail.overlays.cancelAppointment.buttonAppointmentCancellation' | transloco"
        [variation]="'secondary'"
        (click)="cancelAppointment()"
      ></button>
      <!--    <cg-paragraph-->
      <!--      class="flex-grow text-center [&>div]:px-4 [&>p]:text-[0.8125rem] [&>p]:leading-5 [&>p]:text-cg-anthracite-light-30"-->
      <!--      [content]="separatorAlternative"-->
      <!--    >-->
      <!--    </cg-paragraph>-->
      <button
        class="mx-auto mt-4 flex flex-row items-center justify-center gap-2 text-[13px] d:w-56"
        (click)="openCallDialog()"
      >
        <cg-icon class="h-6 w-6" [content]="cccAgentSmallIcon"></cg-icon>
        <div
          class="text-cg-anthracite-light-30"
          [innerHTML]="'channelSwitch.models.general.selfCall' | transloco"
        ></div>
      </button>
    </div>
  </div>
</ng-template>
